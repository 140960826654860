
import { defineComponent } from 'vue'
import StyledItemOfVoucher from '@/styled-components/components/ItemOfVoucher'

export default defineComponent({
  name: 'ItemOfVoucher',
  components: {
    StyledItemOfVoucher
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    pnr: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: false
    },
    mobileUrl: {
      type: String,
      default: null
    },
    disableClick: {
      type: Boolean,
      default: false
    },
    cursor: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClickLabel(e, disableClick) {
      if (this.mobileUrl) {
        e.stopPropagation()
        return
      }

      if (disableClick) e.preventDefault()
    }
  }
})
