
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'
import Title from '@/components/SFSTitle.vue'
import MatrixHotel from '@/components/SFSMatrixHotel.vue'
import ItemOfVouchers from '@/components/ItemOfVoucher/Index.vue'
import moment from 'moment'
import _ from 'lodash'
import { FetchHotelType } from '@/types/hotel'
import { useRouter } from 'vue-router'
import StyledSFSHotelBook from '@/styled-components/components/SFSHotelBook'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { StoreType } from '@/types/store'
import Passenger from '@/models/Passenger'
import { API_SERVICE } from '@/constants/apiService'
import { getUser } from '@/utils/auth'
import { isJson } from '@/utils/string'
import Voucher from '@/models/Voucher'

export default defineComponent({
  name: 'SFSHotelBook',
  components: { Title, ItemOfVouchers, MatrixHotel, StyledSFSHotelBook },
  mixins: [LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()
    const showAlertTop = ref(false)
    const messagesError = ref<string[]>([])
    const roomSelect = ref(0)
    const dataRoomSelected = ref<any[]>([])
    const theNumberOfPaxsSelectTheMostRoom = ref(0)
    const paxsShareRoom = ref<any>(null)
    const payloadSearchHotels = ref<any>({})
    const optionSelect = ref<any[]>([])
    const isDefaultValue = ref(false)
    const curPaxInfo = ref<any>(null)
    const responseFetchMatrixHotels = ref<any>({})
    const waitingBook = ref(false)
    const listpaxs = ref<any>({})
    const aRoomNeed = ref({
      s_room: 0,
      sd_room: 0,
      t_room: 0,
      q_room: 0
    })

    const paxsGroup = computed(() => store.getters['groupPax/listGroupPax'])
    const pax = computed(() => store.state.groupPax.me)
    const airline = computed(() => store.state.airline)
    const services = computed(() => store.state.vouchers)
    const hotelService = computed(() => services.value.find((s) => s.service_id == 1))
    const dataListHotel = computed(() => store.state.hotel.dataListHotel)

    const validationRoomSelected = () => {
      showAlertTop.value = false
      messagesError.value = []
      const dataRoomSelectedConvert = dataRoomSelected.value.map((item) => parseInt(item))
      if (_.union(dataRoomSelectedConvert).length !== roomSelect.value) {
        messagesError.value.push('Incorrect number of rooms are allocated.')
        showAlertTop.value = true
        return
      }
      let aDataRoomSelected = getInfoDataRoomSelected()
      let countRoomSelected = _.reduce(aDataRoomSelected, (acc, curr) => (acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc), {})
      let isValidSelectRoom = true
      theNumberOfPaxsSelectTheMostRoom.value = 0
      // check passengers choose the same room > 4 -> error
      _.forEach(countRoomSelected, (value) => {
        if (value > 4) {
          isValidSelectRoom = false
        } else {
          if (theNumberOfPaxsSelectTheMostRoom.value < value) {
            theNumberOfPaxsSelectTheMostRoom.value = value
          }
        }
      })
      if (!isValidSelectRoom) {
        messagesError.value.push('The hotel number of rooms greater than 4 people does not exist')
        showAlertTop.value = true
        return
      }
      fetchListHotel()
    }

    const getInfoDataRoomSelected = () => {
      let aDataRoomSelected: any[] = []
      _.forEach(dataRoomSelected.value, (room, index) => {
        let pax = paxsGroup.value[index]
        if (!isInfant(pax)) {
          aDataRoomSelected.push(room)
        }
      })
      return aDataRoomSelected
    }

    const fetchListHotel = () => {
      let dataSearchHotel: FetchHotelType = {
        paxId: pax.value?.passenger_id as number,
        airline_id: airline.value?.id as number,
        airport_code: pax.value?.overnight_station ?? '',
        flight_date: moment(pax.value?.flight_date).format('YYYY-MM-DD'),
        lang_code: 'en', // @TODO this.lang.lang_code,
        aRoomNeed: {
          s_room: 0,
          sd_room: 0,
          t_room: 0,
          q_room: 0
        }
      }
      let aDataRoomSelected = getInfoDataRoomSelected()
      if (paxsGroup.value.length == 1) {
        if (getInfant(paxsGroup.value[0]) != -1) {
          messagesError.value.push('Infant must follow adult.')
          return false
        }
        dataSearchHotel.aRoomNeed.s_room++
      } else if (dataRoomSelected.value.length) {
        setDefaultRoomForInfant()
        let aListPaxs = _.groupBy(aDataRoomSelected, (data) => {
          return data
        })
        _.each(aListPaxs, (t) => {
          switch (t.length) {
            case 1:
              dataSearchHotel.aRoomNeed.s_room++
              break
            case 2:
              dataSearchHotel.aRoomNeed.sd_room++
              break
            case 3:
              dataSearchHotel.aRoomNeed.t_room++
              break
            case 4:
              dataSearchHotel.aRoomNeed.q_room++
              break
            default:
              break
          }
        })
      } else {
        dataSearchHotel.aRoomNeed.s_room = aDataRoomSelected.length
      }
      payloadSearchHotels.value = dataSearchHotel
      // fetch list hotel
      store.dispatch('hotel/fetchDataListHotel', dataSearchHotel)
    }

    const getInfant = (pax) => {
      let string = pax.invoicing_flag
      if (pax && !_.isUndefined(pax.invoicing_flag) && !_.isNull(pax.invoicing_flag) && !_.isEmpty(pax.invoicing_flag)) {
        let position = string.search('INFT')
        if (position == -1) {
          position = string.search('INF')
        }
        return position
      } else {
        let ssrs = pax.ssrs
        var v = ssrs.findLastIndex((o) => o.type == 'INFT')
        if (v != -1) {
          return v
        }
        return ssrs.findLastIndex((o) => o.type == 'INF')
      }
    }

    const setDefaultRoomForInfant = () => {
      let paxs = paxsGroup.value
      let min = 1e9
      //get first room
      _.forEach(paxs, (pax, index) => {
        if (getInfant(pax) == -1) {
          if (dataRoomSelected.value[index] < min) {
            min = dataRoomSelected.value[index]
          }
        }
      })
      //set room for infant
      _.forEach(paxs, (aPax, index) => {
        if (getInfant(aPax) !== -1) {
          dataRoomSelected.value[index] = min
        }
      })

      if (paxsShareRoom.value && !_.isEmpty(paxsShareRoom.value) && !_.isUndefined(paxsShareRoom.value)) {
        _.map(paxsShareRoom.value, (aPax, index) => {
          return (aPax.room = dataRoomSelected.value[index])
        })
      }
    }

    const getListSelected = (dataSelected) => {
      // fill room selected in paxsShareRoom
      _.forEach(dataSelected, (value, key) => {
        paxsShareRoom.value[key].room = value
      })
      dataRoomSelected.value = _.cloneDeep(dataSelected)
    }

    const handleChangeRoomSelect = (data) => {
      roomSelect.value = data.number || roomSelect.value
    }

    const roomAmountChanged = (amount) => {
      roomSelect.value = amount || roomSelect.value
    }

    const initData = () => {
      if (paxsGroup.value.length < 2) {
        router.push({ path: '/hotel-list' })
        return
      }
      if (!_.isNil(pax.value?.params) && !_.isUndefined(pax.value?.has_cancelled_hotel_voucher)) {
        // Has cancelled hotel voucher
        router.push({ path: '/home' })
        return
      }
      // create pax to share room
      paxsShareRoom.value = _.filter(paxsGroup.value, function (pax) {
        // get if it is leader or not invited companion or companion who accept to share room
        return (
          !pax.params?.travel_together ||
          !pax.params.travel_together.leader_id ||
          pax.params.travel_together.confirm_manage_book === 1
        )
      })
      roomSelect.value = paxsShareRoom.value.filter((pax) => {
        return getInfant(pax) == -1
      }).length
      optionSelect.value = _.range(1, paxsShareRoom.value.length + 1)

      let matrixShareRoomData
      if (hotelService.value?.params && isJson(hotelService.value?.params)) {
        matrixShareRoomData = JSON.parse(hotelService.value.params)?.matrix_share_room
      }

      if (matrixShareRoomData) {
        // Exists matrix share room
        roomSelect.value = _.uniqBy(matrixShareRoomData, 'room').length
        _.forEach(matrixShareRoomData, (value) => {
          let indexPaxsShareRoom = _.findIndex(paxsShareRoom.value, (o) => o.passenger_id == value.passenger_id)
          paxsShareRoom.value[indexPaxsShareRoom].room = value.room
        })
        isDefaultValue.value = true
        return
      }

      curPaxInfo.value = _.find(paxsGroup.value, { passenger_id: parseInt(pax.value?.curPaxID ?? '0') })
    }

    const isInfant = (pax) => {
      let ssrs = pax.ssrs
      if (!_.isUndefined(ssrs) && pax.invoicing_flag == 'INFT') {
        let invoicing_flag = _.find(ssrs, function (ssr) {
          return ssr.type == 'INFT'
        })
        if (!invoicing_flag) {
          //define ssr object via amadeus
          let infant = {
            type: 'INFT',
            key: '#INFT',
            ssrValue: {}
          }
          ssrs.push(infant)
        }
      }
      let result = _.find(ssrs, function (ssr) {
        return ssr.type == 'INFT'
      })
      if (result) {
        return true
      } else {
        return false
      }
    }

    const fetchMatrixHotels = () => {
      // fetch Matrix hotels
      store
        .dispatch('hotel/fetchMatrixHotels', payloadSearchHotels.value)
        .then((response) => {
          responseFetchMatrixHotels.value = _.cloneDeep(response)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    // @TODO: refactor code
    // - 1 passenger & has not matrix share room -> make matrix share room then book hotel
    // - use watch for get state
    const saveMatrixShareRoom = () => {
      let paxsNotShareRoom = _.differenceBy(paxsGroup.value, paxsShareRoom.value, 'passenger_id')
      let maxRoomNum = _.maxBy(paxsShareRoom.value, 'room')['room']
      _.each(paxsNotShareRoom, function (pax) {
        pax.room = ++maxRoomNum
      })
      let resultMatrix = [...paxsShareRoom.value, ...paxsNotShareRoom]
      listpaxs.value = _.groupBy(
        _.map(resultMatrix, function (obj) {
          var oTmp = {}
          oTmp['passenger_id'] = obj.id
          oTmp['room'] = obj.room
          oTmp['isInfant'] = getInfant(obj) !== -1
          return oTmp
        }),
        function (obj) {
          return obj.room
        }
      )
      var result = Object.keys(listpaxs.value).map(function (key) {
        return listpaxs.value[key]
      })
      _.each(listpaxs.value, function (t) {
        switch (t.length) {
          case 1:
            aRoomNeed.value.s_room++
            break
          case 2:
            aRoomNeed.value.sd_room++
            break
          case 3:
            aRoomNeed.value.t_room++
            break
          case 4:
            aRoomNeed.value.q_room++
            break
          default:
            break
        }
      })

      let dataSendSms = {
        paxId: pax.value?.id as number,
        airline_id: airline.value?.id as number,
        airport_code: pax.value?.overnight_station,

        groups_passengers: result,
        paxLeaderId: pax.value?.id
      }
      new Passenger(API_SERVICE.SFS).sendSMSShareroom(dataSendSms).then((response) => {
        if (response.success) {
          router.push({ path: '/hotel-list' })
          return
        }
      })
    }

    watch(pax, () => {
      if (!pax.value?.curPaxID || _.isUndefined(pax.value?.params.flow_status)) {
        router.push({ path: '/pax-contact-confirm' })
        return
      }

      if (!_.isEmpty(services.value) && !_.isEmpty(paxsGroup.value)) {
        initData()
      }
    })

    watch(services, () => {
      if (!_.isEmpty(pax.value) && !_.isEmpty(paxsGroup.value)) {
        initData()
      }
    })

    watch(paxsGroup, () => {
      if (paxsGroup.value.length < 2) {
        router.push({ path: '/home' })
        return
      }
      if (!_.isEmpty(pax.value) && !_.isEmpty(services.value)) {
        initData()
      }
    })

    // Auto save matrix when only 1 passenger accept share room
    watch(paxsShareRoom, () => {
      if (paxsShareRoom.value.length === 1) {
        paxsShareRoom.value[0].room = 1
        saveMatrixShareRoom()
        waitingBook.value = true
      }
    })

    watch(dataListHotel, () => {
      showAlertTop.value = false
      messagesError.value = []
      if (!dataListHotel.value.length) {
        fetchMatrixHotels()
        return
      }
      saveMatrixShareRoom()
    })

    watch(showAlertTop, () => {
      if (showAlertTop.value) {
        for (const msg of messagesError.value) {
          ElNotification({
            title: 'Error',
            message: msg,
            type: 'error',
            duration: 5000
          })
        }

        showAlertTop.value = false
      }
    })

    watch(responseFetchMatrixHotels, () => {
      if (responseFetchMatrixHotels.value.success) {
        showAlertTop.value = false
        messagesError.value = []

        let typeRoom = 'Single'
        if (theNumberOfPaxsSelectTheMostRoom.value === 2) {
          typeRoom = 'Double'
        } else if (theNumberOfPaxsSelectTheMostRoom.value === 3) {
          typeRoom = 'Triple'
        } else if (theNumberOfPaxsSelectTheMostRoom.value === 4) {
          typeRoom = 'Quadruple'
        }

        if (!_.isEmpty(responseFetchMatrixHotels?.value?.data?.matrix)) {
          showAlertTop.value = true
          messagesError.value.push(
            `There are no hotels with ${typeRoom} rooms available at the moment. Change your room sharing preference.`
          )
          return
        }

        saveMatrixShareRoom()
        return
      }
      saveMatrixShareRoom()
      if (!dataListHotel.value.length) {
        console.log('saveMatrixShareRoom')
        router.push({ path: '/no-hotels-available' })
      }
    })

    // created
    if (!_.isEmpty(services.value) && !_.isEmpty(paxsGroup.value) && !_.isEmpty(pax.value)) {
      initData()
    }

    onMounted(() => {
      const mountedInterval = setInterval(async () => {
        // wait for airline loaded
        if (!_.isNil(airline.value) || !_.isNil(pax.value)) {
          const user = getUser()
          const paxId = store.state['groupPax']?.me?.passenger_id
          if (!user || !paxId) return
          const { data: vouchers } = await new Voucher().getListVoucherDetail({
            airline_id: user.airline.id,
            paxId: paxId as number
          })
          store.dispatch('setVouchers', vouchers)
          store.dispatch('taxi/getTaxi', {
            paxId: pax.value?.id,
            airline_id: airline.value?.id,
            airport_code: pax.value?.overnight_station
          })
          clearInterval(mountedInterval)
        }
      }, 500)
    })

    return {
      showAlertTop,
      validationRoomSelected,
      getListSelected,
      handleChangeRoomSelect,
      roomAmountChanged,
      responseFetchMatrixHotels,
      isDefaultValue,
      roomSelect,
      paxsShareRoom,
      messagesError
    }
  }
})
