
import { computed, defineComponent } from 'vue'
import { ElNotification } from 'element-plus'
import { VueTelInput } from 'vue-tel-input'
import store from '@/store'
import Passenger from '@/models/Passenger'
import StyledPassengerDetail from '@/styled-components/pages/passenger-detail'
import ItemOfVoucher from '@/components/ItemOfVoucher/Index.vue'
import SFSTravelersAndServiceInGroup from '@/components/SFSTravelersAndServiceInGroup.vue'
import { useRouter } from 'vue-router'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import SfsApiSendSmsMail from '@/models/SFSAPI_SendSMSMail'
import LangMixinsVue from '../SearchAndSelectPassengers/Mixin/LangMixins.vue'

export default defineComponent({
  name: 'PassengerDetail',
  components: {
    StyledPassengerDetail,
    ItemOfVoucher,
    SFSTravelersAndServiceInGroup,
    VueTelInput
  },
  mixins: [FetchAllMixinsVue, LangMixinsVue],
  setup() {
    return {
      flightInfo: computed(() => store.state.flightInfo),
      paxThisIsMe: computed(() => store.state['groupPax'].me),
      listGroupPaxExcludeMe: computed(() => store.state['groupPax'].listGroupPaxExcludeMe)
    }
  },
  data() {
    return {
      phonePax: '',
      emailPax: '',
      validatePhone: true,
      checkEmptyValue: true,
      checkEmail: false,
      tempPhone: '',
      router: useRouter()
    }
  },
  watch: {
    paxThisIsMe: {
      handler: function (newValue) {
        this.phonePax = newValue.phone_number || ''
        this.emailPax = newValue.email_address || ''
        this.cleanPhoneNumber(this.phonePax)
      },
      deep: true
    }
  },
  created() {
    this.fetchAll()
    this.initData()
  },
  methods: {
    initData() {
      this.phonePax = this.paxThisIsMe?.phone_number || ''
      this.emailPax = this.paxThisIsMe?.email_address || ''
      this.cleanPhoneNumber(this.phonePax)
    },
    checkPhoneValid: function ({ formatted, valid }) {
      this.checkEmptyValue = true
      this.validatePhone = valid
      if (!formatted) {
        this.validatePhone = true
      }
      this.tempPhone = formatted
    },
    changeEmail() {
      this.checkEmptyValue = true
    },
    changePhone() {
      this.cleanPhoneNumber(this.phonePax)
      if (this.phonePax.length == 0) this.validatePhone = true
    },
    cleanPhoneNumber(number) {
      let addPLusSign = false
      if (this.hasLeadingPlus(number)) {
        addPLusSign = true
      }
      number = number.replace(/\+/g, '')
      if (this.hasLeadingZeros(number)) {
        addPLusSign = true
        number = number.replace(/^0+/, '')
      }
      if (addPLusSign) {
        number = '+' + number
      }
      this.phonePax = number
    },

    hasLeadingZeros(str) {
      return /^00+/.test(str)
    },
    hasLeadingPlus(str) {
      return /^\++/.test(str)
    },
    checkValidEmail() {
      // eslint-disable-next-line
      this.checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailPax)
    },
    checkValidInput() {
      let self = this
      if (self.phonePax.length == 1) self.validatePhone = false
      self.checkValidEmail()
      if (self.phonePax || self.emailPax) {
        if (self.phonePax) {
          if (!self.validatePhone) {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: 'Please enter valid phone or email address',
              type: 'error',
              duration: 3000
            })
            return false
          }
          if (self.tempPhone !== '') {
            let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
            if (!numbers.includes(this.tempPhone.charAt(this.tempPhone.length - 1))) {
              ElNotification.closeAll()
              ElNotification({
                title: 'Error',
                message: 'Please enter valid phone or email address',
                type: 'error',
                duration: 3000
              })
              return false
            }
          }
        }
        if (self.emailPax) {
          if (!self.checkEmail) {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: 'Please enter valid phone or email address',
              type: 'error',
              duration: 3000
            })
            return false
          }
          if (self.emailPax === null || self.emailPax === '') {
            ElNotification.closeAll()
            ElNotification({
              title: 'Error',
              message: 'Please enter your email address.',
              type: 'error',
              duration: 3000
            })
            return false
          }
        }
      }
      if (!self.phonePax && !self.emailPax) {
        this.checkEmptyValue = false
        ElNotification.closeAll()
        ElNotification({
          title: 'Error',
          message: 'Please enter your phone number or email address.',
          type: 'error',
          duration: 3000
        })
        return false
      }
      return true
    },
    async handleSendSmsEmail() {
      const airlineToken = store.state.airline?.unique_token
      const airportCode = this.paxThisIsMe?.overnight_station
      const paxId = this.paxThisIsMe?.id ?? 0
      const email = this.emailPax
      const phone = this.phonePax
      const dataSend = {
        data: {
          Passengers: [{ Mail: email ?? '', PassengerId: paxId ?? 0, PhoneNumber: phone ?? '' }],
          fromIssueVoucher: 0,
          fromSelectPassenger: 0,
          AirportCode: airportCode ?? ''
        },
        AirlineToken: airlineToken ?? ''
      }
      const sfsApi = new SfsApiSendSmsMail()
      const { success, message } = await sfsApi.sendSmsMail(dataSend)
      return success
    },
    async updatePhoneAndEmail() {
      let isValid = this.checkValidInput()
      if (!isValid) {
        return
      }
      const { success, message } = await new Passenger().updateEmailAndPhoneNumber({
        passenger_id: this.paxThisIsMe?.id ?? 0,
        email_address: this.emailPax,
        phone_number: this.phonePax
      })
      if (success) {
        const resultSendSmsEmail = await this.handleSendSmsEmail()
        if (resultSendSmsEmail) {
          // Notify success
          ElNotification.closeAll()
          ElNotification({
            title: 'Success',
            message: 'Update success',
            type: 'success',
            duration: 3000
          })
          // Update info of pax thisIsMe
          store.dispatch('groupPax/setMe', {
            ...this.paxThisIsMe,
            email_address: this.emailPax,
            phone_number: this.phonePax
          })
          this.router.push({ name: 'Home' })
        } else {
          ElNotification.closeAll()
          ElNotification({
            title: 'Error',
            message: 'Action failed',
            type: 'error',
            duration: 3000
          })
        }
      } else {
        ElNotification.closeAll()
        ElNotification({
          title: 'Error',
          message: message,
          type: 'error',
          duration: 3000
        })
      }
      return
    }
  }
})
