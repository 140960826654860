import styled from 'vue3-styled-components'
const itemProp = { colorBackground: String }
const ItemOfVoucher = styled('section', itemProp)`
  font-size: 16px;
  letter-spacing: 0px;
  font-family: 'Roboto';
  cursor: pointer;
  color: ${(props) => (props.colorBackground ? props.colorBackground : '#660066')} !important;
  .item-of-vouchers {
    &__wrapper {
      .item-of-vouchers__name {
        cursor: pointer;
      }
      .ws-textbox__custom {
        height: 84%;
        padding: 0;
        border-radius: 12px;
        box-shadow: 0px 2px 10px #33333319;
      }
      .ws-textbox__border {
        border: 1px solid ${(props) => (props.colorBackground ? props.colorBackground : 'white')};
        box-shadow: ${(props) => (props.colorBackground ? props.colorBackground : 'white')} 0px 1px 3px 0px;
      }
      .text-cancelled {
        font-weight: 500;
      }
      .img-voucher {
        width: 35px;
        height: 35px;
      }
      .hd-item-title-color {
        color: #474747;
      }
      .mobile-url-link {
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--el-color-primary);
          text-decoration: underline;
        }
      }
    }
    &__mobile-url {
      margin-inline: 0.5rem;
    }
    &__icon {
      width: 45px;
      height: 90%;
      padding: 0.85rem 0rem;
      border-radius: var(--voucher-icon-border-radius);
      background-color: ${(props) => (props.colorBackground ? props.colorBackground : 'white')};
    }
    &__pnr {
      padding-inline: 1rem;
    }
    &__name {
      margin-inline: 17px;
    }
  }
  .d-flex {
    display: flex !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  ::v-deep .disabled {
    opacity: 0.7;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .font-weight-bold {
    font-weight: bold;
  }
  .hd-service-background-color {
    background-color: #fff;

    .hd-item-title-color {
      color: #474747;
    }
  }
`

export default ItemOfVoucher
