import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "item-of-vouchers__wrapper" }
const _hoisted_2 = { class: "item-of-vouchers__icon-and-name d-flex align-items-center" }
const _hoisted_3 = { class: "item-of-vouchers__icon d-flex justify-content-center align-items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "item-of-vouchers__noti"
}
const _hoisted_6 = {
  key: 1,
  class: "item-of-vouchers__mobile-url"
}
const _hoisted_7 = {
  key: 0,
  class: "item-of-vouchers__pnr hd-item-title-color"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_StyledItemOfVoucher = _resolveComponent("StyledItemOfVoucher")!

  return (_openBlock(), _createBlock(_component_StyledItemOfVoucher, { "color-background": _ctx.color }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["ws-textbox ws-textbox__custom d-flex justify-content-between align-items-center hd-service-background-color", { 'ws-textbox__border': _ctx.border }]),
          style: _normalizeStyle({ '--background-color': _ctx.color, cursor: _ctx.cursor })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("img", {
                src: `/${_ctx.icon}`,
                alt: "",
                srcset: "",
                class: "img-voucher"
              }, null, 8, _hoisted_4)
            ]),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mobileUrl ? 'a' : 'span'), {
              href: _ctx.mobileUrl || null,
              target: _ctx.mobileUrl ? '_blank' : null,
              class: _normalizeClass(["item-of-vouchers__name hd-item-title-color", { 'mobile-url-link': _ctx.mobileUrl }]),
              style: _normalizeStyle({ cursor: _ctx.cursor }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickLabel($event, _ctx.disableClick)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
                (_ctx.text)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.text), 1))
                  : _createCommentVNode("", true),
                (_ctx.mobileUrl)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Link)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["href", "target", "class", "style"]))
          ]),
          (_ctx.pnr)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, "PNR: " + _toDisplayString(_ctx.pnr), 1))
            : _createCommentVNode("", true)
        ], 6)
      ])
    ]),
    _: 1
  }, 8, ["color-background"]))
}