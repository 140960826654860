
import { defineComponent, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Title from '@/components/SFSTitle.vue'
import HotelCard from '@/components/SFSHotelCard.vue'
import ItemOfVouchers from '@/components/ItemOfVoucher/Index.vue'
import moment from 'moment'
import _ from 'lodash'
import StyledSFSHotelList from '@/styled-components/components/SFSHotelList'
import LangMixinsVue from '@/views/SearchAndSelectPassengers/Mixin/LangMixins.vue'
import { FetchHotelType } from '@/types/hotel'
import Voucher from '@/models/Voucher'
import { isJson } from '@/utils/string'
import { StoreType } from '@/types/store'
import FetchAllMixinsVue from '../Mixins/FetchAllMixins.vue'
import Passenger from '@/models/Passenger'

const SORT_HOTEL_ESTIMATE_COST = 5

export default defineComponent({
  name: 'SFSHotelList',
  components: { Title, ItemOfVouchers, HotelCard, StyledSFSHotelList },
  mixins: [LangMixinsVue],
  setup() {
    const store = useStore<StoreType>()
    const router = useRouter()
    const route = useRoute()
    const google = ref<any>(null)

    const loading = ref(true)
    const spinnerActive = ref(false)
    const hasCancelHotelVoucher = ref(false)
    const curNumberRoomNeed = ref<any>(null)
    const numberRoomNeed = ref<any>({})
    const storeTaxiOption = ref(0)
    const validGGApi = ref(true)
    const curPaxInfo = ref<any>(null)

    const dataListHotel = computed(() => store.state.hotel?.dataListHotel)
    const paxsGroup = computed(() => store.getters['groupPax/listGroupPax'])
    const pax = computed(() => store.state.groupPax.me)
    const overNightStation = computed(() => store.state.overnightStation)
    const airline = computed(() => store.state.airline)
    const voucherHotel = computed(() => store.state.selectRoom.paxsHotel)
    const isInventoryList = computed(() => store.state.hotel.isInventoryList)

    // wait for gmap api loaded
    let gmapApiLoading = ref(true)
    const loadGmapInterval = setInterval(() => {
      if (!_.isNil(window['google'])) {
        google.value = window['google']
        gmapApiLoading.value = false
        clearInterval(loadGmapInterval)
      }
    }, 1000)

    let taxiLoading = ref(true)
    let hotelLoading = ref(true)

    const availableChangeRoomAllocation = computed(() => paxsGroup.value.length > 1)

    watch([gmapApiLoading, taxiLoading, hotelLoading], ([curGmapApiLoading, curTaxiLoading, curHotelLoading]) => {
      loading.value = curGmapApiLoading || curTaxiLoading || curHotelLoading
    })

    const handleChangeRoomAllocation = () => {
      router.push({ path: '/hotel-book' })
    }

    const handleBookHotel = (hotel) => {
      spinnerActive.value = true
      let payload: any = {}
      if (hotel.reservations) {
        // Book reservation
        payload = renderLockShareRoom(payload, hotel.reservations[0])
        payload.date = moment(pax.value?.flight_date).format('YYYY-MM-DD')
        payload.room_id = hotel.reservations[0].room_id
        payload.hotel_id = hotel.hotel_id
        payload.reservations = _.map(hotel.reservations, function (o) {
          return {
            id: o.id,
            selected_mealplan: false,
            selected_lunch: false,
            selected_breakfast: false
          }
        })
      } else {
        // Book partner
        payload.date = moment(pax.value?.flight_date).format('YYYY-MM-DD')
        payload.room_id = hotel.id
        payload.hotel_id = hotel.hotel_id
      }
      payload.self_service = hotel.self_service
      payload.is_inventory = isInventoryList.value
      // Make params auto assign taxi when hotel has auto assign taxi, and has only one taxi company
      processParamTaxi(payload, hotel).then((payload) => {
        payload.language = checkLanguage()
        payload.hotel_list_page = true
        spinnerActive.value = true

        payload.paxId = pax.value?.id
        payload.airline_id = airline.value?.id
        payload.airport_code = pax.value?.overnight_station

        store.dispatch('hotel/bookListHotel', payload).then((res) => {
          if (typeof res.is_research_hotel !== 'undefined' && res.is_research_hotel === true) {
            spinnerActive.value = false
            window.location.reload()
            return
          }

          if (res.success) {
            spinnerActive.value = false
            // Set voucher_id for PaxsGroup
            let resBookHotel
            _.each(res.data, function (data) {
              // Response data from book hotel partner and book reservation is different
              data = !_.isUndefined(data[0]) ? data : [data]
              resBookHotel = _.first(data)
              _.each(paxsGroup.value, function (pax) {
                if (
                  _.some(resBookHotel.passengers, function (e) {
                    // Check element in array exists
                    // equivalent with includes (lodash) and contains (underscore) but don't care about data type
                    return e == pax.id
                  })
                ) {
                  pax.voucher_id = resBookHotel.voucher_id
                }
              })
            })
            // If there is only one voucher then redirect to select-room page (voucher)
            let url = '/select-room'
            let paxs_group = _.filter(paxsGroup.value, function (pax) {
              return !isInfant(pax)
            })
            let pax_id = paxs_group[0].id

            // fetch new vouchers
            new Voucher()
              .getListVoucherDetail({
                airline_id: airline.value?.id ?? 0,
                paxId: pax.value?.passenger_id
              })
              .then((res) => {
                store.dispatch('setVouchers', res.data)
              })

            router.push({ path: url, query: Object.assign({}, route.query, { pax_voucher_id: pax_id }) }) // Hotel voucher
            return
          } else {
            router.push({ path: '/no-hotels-available', query: { has_booked_failed: 1 } })
          }

          spinnerActive.value = false
        })
      })
    }

    const renderLockShareRoom = (payload, reservation) => {
      let roomNeed = curNumberRoomNeed.value
      let room_available = {
        s: reservation.s_room,
        d: reservation.sd_room,
        t: reservation.t_room,
        q: reservation.q_room
      }
      let selRoom = {
        single: !_.isUndefined(roomNeed.s_room) ? roomNeed.s_room : 0,
        double: !_.isUndefined(roomNeed.sd_room) ? roomNeed.sd_room : 0,
        triple: !_.isUndefined(roomNeed.t_room) ? roomNeed.t_room : 0,
        quad: !_.isUndefined(roomNeed.q_room) ? roomNeed.q_room : 0
      }

      let share_room = { single: 0, double: 0, tripple: 0, quad: 0 }

      if (!_.isUndefined(room_available) && !_.isEmpty(room_available)) {
        if (selRoom.single > room_available.s) {
          selRoom.double = selRoom.double + (selRoom.single - room_available.s)
          selRoom.single = room_available.s
          console.log('The case 1')
        } else if (paxsGroup.value.length == 1 && selRoom.single == 0 && room_available.s > 0) {
          // The case on click change [Select] selectReservation of hotel
          selRoom.single = 1
          selRoom.double = 0
          console.log('The case 2')
        }
        share_room.single = selRoom.single
        if (selRoom.double > room_available.d) {
          selRoom.triple = selRoom.triple + (selRoom.double - room_available.d)
          selRoom.double = room_available.d
          console.log('The case 3')
        } else if (paxsGroup.value.length == 2 && selRoom.double == 0 && room_available.d > 0) {
          // The case on click change [Select] selectReservation of hotel
          selRoom.double = 1
          selRoom.triple = 0
          console.log('The case 4')
        }
        share_room.double = selRoom.double
        if (selRoom.triple > room_available.t) {
          selRoom.quad = selRoom.quad + (selRoom.triple - room_available.t)
          selRoom.triple = room_available.t
          console.log('The case 5')
        } else if (paxsGroup.value.length == 3 && selRoom.triple == 0 && room_available.t > 0) {
          // The case on click change [Select] selectReservation of hotel
          selRoom.triple = 1
          selRoom.quad = 0
          console.log('The case 6')
        }
        share_room.tripple = selRoom.triple
        if (selRoom.quad < room_available.q) {
          share_room.quad = selRoom.quad
          console.log('The case 7')
        }
      }
      payload.s_room = share_room.single
      payload.sd_room = share_room.double
      payload.t_room = share_room.tripple
      payload.q_room = share_room.quad
      return payload
    }

    const processParamTaxi = async (payload, hotel) => {
      // Check whether enable auto assign taxi then make params for it
      let overnight_station = overNightStation.value
      let transportId = hotel.additional_transport_id
      let distanceHotel = hotel.road_distance_airport || 0

      switch (storeTaxiOption.value) {
        // One way to hotel
        case 1:
          transportId = 2
          break
        // Two way to hotel <--> airport
        case 2:
          transportId = 3
          break
        // One way to airport
        case 3:
          transportId = airline.value
          break
      }

      if (_.includes([2, 3, 5], transportId)) {
        // Calculate distance by gg map api
        let origins: any[] = []
        let destinations: any[] = []
        let paxIds = _.map(paxsGroup.value, (pax) => pax.id)
        payload.assignTaxiParams = {
          hotel_id: payload.hotel_id,
          passenger: paxIds,
          date: payload.date,
          transport_id: transportId,
          airline_id: airline.value?.id,
          airport_code: !_.isEmpty(overnight_station) ? overnight_station?.code : airline.value?.airport_code,
          airport_id: !_.isEmpty(overnight_station) ? overnight_station?.id : airline.value?.airport_id,
          is_auto_taxi: true
        }
        switch (transportId) {
          case 2:
            // Airport -> Hotel
            payload.assignTaxiParams.option_taxi = 1
            payload.assignTaxiParams.way_option = 1
            payload.assignTaxiParams.from_address = !_.isEmpty(overnight_station)
              ? overnight_station?.name
              : airline.value?.airport_name
            payload.assignTaxiParams.to_address = hotel.address
            origins = [
              !_.isEmpty(overnight_station)
                ? `${overnight_station?.geo_lat}, ${overnight_station?.geo_lon}`
                : `${airline.value?.geo_lat}, ${airline.value?.geo_lon}`
            ]
            destinations = [`${hotel.geo_location_latitude}, ${hotel.geo_location_longitude}`]
            break
          case 5:
            // Hotel -> Airport
            payload.assignTaxiParams.option_taxi = 3
            payload.assignTaxiParams.way_option = 1
            payload.assignTaxiParams.from_address = hotel.address
            payload.assignTaxiParams.to_address = !_.isEmpty(overnight_station)
              ? overnight_station?.name
              : airline.value?.airport_name
            origins = [`${hotel.geo_location_latitude}, ${hotel.geo_location_longitude}`]
            destinations = [
              !_.isEmpty(overnight_station)
                ? `${overnight_station?.geo_lat},${overnight_station?.geo_lon}`
                : `${airline.value?.geo_lat}, ${airline.value?.geo_lon}`
            ]
            break
          case 3:
            // 2 way
            payload.assignTaxiParams.option_taxi = 2
            payload.assignTaxiParams.way_option = 2
            payload.assignTaxiParams.from_address = !_.isEmpty(overnight_station)
              ? overnight_station?.name
              : airline.value?.airport_name
            payload.assignTaxiParams.to_address = hotel.address
            origins = [
              !_.isEmpty(overnight_station)
                ? `${overnight_station?.geo_lat},${overnight_station?.geo_lon}`
                : `${airline.value?.geo_lat}, ${airline.value?.geo_lon}`,
              `${hotel.geo_location_latitude}, ${hotel.geo_location_longitude}`
            ]
            destinations = [
              `${hotel.geo_location_latitude}, ${hotel.geo_location_longitude}`,
              !_.isEmpty(overnight_station)
                ? `${overnight_station?.geo_lat},${overnight_station?.geo_lon}`
                : `${airline.value?.geo_lat}, ${airline.value?.geo_lon}`
            ]
            break
        }

        let maximum_walking_distance =
          overnight_station?.airline_airport_detail.maximum_walking_distance ||
          airline.value?.params.airline_config_taxi.maximum_walking_distance
        let taxiConfig = airline.value?.params.airline_config_taxi
        let taxiAirportConfig = overnight_station?.airline_airport_detail
        let km_rate_airline = Number(taxiConfig.km_rate) !== 0 ? taxiConfig.km_rate : 3
        let starting_tariff_airline = Number(taxiConfig.starting_tariff) !== 0 ? taxiConfig.starting_tariff : 0
        let km_rate = parseInt(taxiAirportConfig.km_rate ?? '0') != 0 ? taxiAirportConfig.km_rate : km_rate_airline
        let starting_tariff = taxiAirportConfig.starting_tariff ?? starting_tariff_airline
        let airline_taxi_fee = airline.value && airline.value?.taxi_fee ? airline.value?.taxi_fee : 0
        let waiting_tarif =
          airline.value && airline.value.params && airline.value.params.taxi_waiting_tarif
            ? airline.value.params.taxi_waiting_tarif
            : 10
        let distance = distanceHotel * payload.assignTaxiParams.way_option
        let distanceOneWay = distanceHotel
        let distance2Way = 0
        if (validGGApi.value) {
          let locationRequest = {
            origins: origins,
            destinations: destinations,
            travelMode: 'DRIVING',
            unitSystem: google.value.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false
          }
          let response: any = await getDistanceMatrix(locationRequest)
          if (response) {
            let rows = response.rows
            let distanceGoogle: number | null = null
            // Calculate way 1
            if (rows[0] && rows[0].elements[0].status == 'OK') {
              distanceGoogle = distanceOneWay = rows[0].elements[0].distance.value / 1000
            }

            distance = distanceGoogle ?? distance
            // Calculate way 2 if exist. And make sure way 1 is OK by variable distance
            if (rows[1] && rows[1].elements[1].status == 'OK' && distance) {
              distance2Way = rows[1].elements[1].distance.value / 1000

              payload.assignTaxiParams.price_airport_to_hotel =
                parseFloat(km_rate) * parseFloat(distance.toFixed(2)) + parseFloat(starting_tariff)
              payload.assignTaxiParams.distance_airport_to_hotel = distance

              payload.assignTaxiParams.price_hotel_to_airport =
                parseFloat(km_rate) * parseFloat(distance2Way.toFixed(2)) + parseFloat(starting_tariff)
              payload.assignTaxiParams.distance_hotel_to_airport = distance2Way
            }
          }
        }
        let priceTaxi = parseFloat(km_rate) * parseFloat(distance.toFixed(2)) + parseFloat(starting_tariff)
        if (distance2Way) {
          priceTaxi += parseFloat(km_rate) * parseFloat(distance2Way.toFixed(2)) + parseFloat(starting_tariff)
        }
        payload.assignTaxiParams.distance = distanceOneWay
        payload.assignTaxiParams.total_price = priceTaxi
        payload.isAutoAssignTaxi = priceTaxi && distanceHotel * 1000 > maximum_walking_distance ? true : false
      }

      return payload
    }

    const checkLanguage = () => {
      let langCode = _.first(paxsGroup.value).language ?? 'en'
      if (_.size(paxsGroup.value) > 1) {
        let checkLang = _.find(paxsGroup.value, function (o) {
          return o.language?.toLowerCase() != langCode.toLowerCase() //TODO: check
        })
        if (!_.isEmpty(checkLang)) {
          langCode = 'en'
        }
      }
      return langCode
    }

    const getDistanceMatrix = (data) => {
      // Make promise of get distance gg api in order to waiting finish calculate distance taxi then book
      var service = new google.value.maps.DistanceMatrixService()
      return new Promise((resolve, reject) => {
        service.getDistanceMatrix(data, (response, status) => {
          resolve(response)
        })
      })
    }

    const initData = async () => {
      let reservationId = pax.value?.reservation_id ?? 0
      let voucherId = pax.value?.voucher_id ?? 0
      const paxId = pax.value?.id
      const passenger = new Passenger()
      const { data: overnightStation } = await passenger.getOvernightStation(paxId ?? 0, airline.value?.id ?? 0)
      store.dispatch('setOvernightStation', overnightStation)
      store
        .dispatch('selectRoom/fetchVoucherHotel', {
          passenger_id: [paxId],
          reservation_id: reservationId,
          voucher_id: voucherId
        })
        .then(function () {
          if (pax.value?.has_cancelled_hotel_voucher && _.isEmpty(voucherHotel.value)) {
            // Has cancelled hotel voucher
            hasCancelHotelVoucher.value = true
            loading.value = gmapApiLoading.value
            return
          }

          //TODO: check pax.value.curPaxID
          // if (
          //   paxsGroup.value.length > 1 &&
          //   (_.isUndefined(pax.value.curPaxID) || _.isNull(pax.value.curPaxID) || pax.value.curPaxID == '')
          // ) {
          //   // Group more than 2 persons and don't know who are you
          //   router.push({ path: '/pax-contact-confirm' })
          //   return
          // }
          // Get Hotel voucher & check existing
          store
            .dispatch('taxi/getTaxi', {
              paxId: pax.value?.id,
              airline_id: airline.value?.id,
              airport_code: pax.value?.overnight_station
            })
            .then(async (response) => {
              taxiLoading.value = false
              if (_.size(response)) {
                // Voucher exists
                router.push({
                  path: '/select-room',
                  query: {
                    ...route.query,
                    pax_voucher_id: response.passenger_id, //TODO: check
                    reservation_id: reservationId,
                    voucher_id: voucherId
                  }
                })
                return
              }
              // No voucher exists
              // Fetch hotel services info
              const { data: vouchers } = await new Voucher().getListVoucherDetail({
                airline_id: airline.value?.id ?? 0,
                paxId
              })
              store.dispatch('setVouchers', vouchers)
              let dataHotel = store.state.vouchers.find((v) => v.service_id == 1)
              const dataTaxi = store.state.vouchers.find((v) => v.service_id == 4)
              if (dataTaxi?.store_taxi_option) {
                if (typeof dataTaxi.store_taxi_option !== 'undefined') {
                  storeTaxiOption.value = dataTaxi.store_taxi_option === null ? 0 : dataTaxi.store_taxi_option
                }
              }

              if (paxsGroup.value.length == 1) {
                // Single passenger
                fetchListHotel(dataHotel)
                hotelLoading.value = false
              } else {
                if (dataHotel && isJson(dataHotel?.params)) {
                  dataHotel.params = JSON.parse(dataHotel?.params)
                }
                // Group with more than 1 passenger
                if (!_.isNull(dataHotel?.params) && !_.isUndefined(dataHotel?.params.matrix_share_room)) {
                  // Matrix existed
                  fetchListHotel(dataHotel)
                  hotelLoading.value = false
                  return
                }
                hotelLoading.value = false
                router.push({ path: '/hotel-book' })
              }
            })
        })

      // Get current passenger info
      curPaxInfo.value = pax.value
      if (paxsGroup.value.length > 1) {
        curPaxInfo.value = _.find(paxsGroup.value, { passenger_id: parseInt(pax.value?.curPaxID) })
      }
    }

    const fetchListHotel = (dataHotel) => {
      let dataSearchHotel = getARoomNeed(dataHotel)
      store.dispatch('hotel/fetchDataListHotel', dataSearchHotel).then(() => {
        if (_.size(dataListHotel.value) == 0) {
          console.log('hotel/fetchDataListHotel')
          router.push({ path: '/no-hotels-available' })
          return
        }
      })
    }

    const getARoomNeed = (dataHotel) => {
      let dataSearchHotel: FetchHotelType = {
        paxId: pax.value?.id as number,
        airline_id: airline.value?.id as number,
        airport_code: pax.value?.overnight_station ?? '',
        flight_date: moment(pax.value?.flight_date).format('YYYY-MM-DD'),
        lang_code: 'en', // @TODO this.lang.lang_code,
        aRoomNeed: {
          s_room: 0,
          sd_room: 0,
          t_room: 0,
          q_room: 0
        }
      }
      if (paxsGroup.value.length == 1) {
        dataSearchHotel.aRoomNeed.s_room++
      } else if (dataHotel.params != null && typeof dataHotel.params.matrix_share_room !== 'undefined') {
        let maxtrix_share_room = dataHotel.params.matrix_share_room.filter((room) => {
          return room.isInfant != 'true'
        })
        let listPaxs = _.groupBy(maxtrix_share_room, function (o) {
          return o.room
        })
        _.each(listPaxs, function (t) {
          // Remove INFT
          _.remove(t, function (p) {
            return p.isInfant == true
          })
          switch (t.length) {
            case 1:
              dataSearchHotel.aRoomNeed.s_room++
              break
            case 2:
              dataSearchHotel.aRoomNeed.sd_room++
              break
            case 3:
              dataSearchHotel.aRoomNeed.t_room++
              break
            case 4:
              dataSearchHotel.aRoomNeed.q_room++
              break
            default:
              break
          }
        })
      } else {
        dataSearchHotel.aRoomNeed.s_room = paxsGroup.value.length
      }
      numberRoomNeed.value = dataSearchHotel.aRoomNeed
      curNumberRoomNeed.value = numberRoomNeed.value
      return dataSearchHotel
    }

    const isInfant = (pax) => {
      let ssrs = pax.ssrs
      if (!_.isUndefined(ssrs) && pax.invoicing_flag == 'INFT') {
        let invoicing_flag = _.find(ssrs, function (ssr) {
          return ssr.type == 'INFT'
        })
        if (!invoicing_flag) {
          //define ssr object via amadeus
          let infant = {
            type: 'INFT',
            key: '#INFT',
            ssrValue: {}
          }
          ssrs.push(infant)
        }
      }
      let result = _.find(ssrs, function (ssr) {
        return ssr.type == 'INFT'
      })
      if (result) {
        return true
      } else {
        return false
      }
    }

    watch(paxsGroup, () => {
      if (!_.isEmpty(pax.value) && !_.isEmpty(airline.value)) {
        initData()
      }
    })

    watch(pax, () => {
      if (!_.isEmpty(paxsGroup.value) && !_.isEmpty(airline.value)) {
        initData()
      }
    })

    watch(airline, () => {
      if (!_.isEmpty(paxsGroup.value) && !_.isEmpty(pax.value)) {
        initData()
      }
    })

    watch(dataListHotel, () => {
      if (_.size(dataListHotel.value) == 0) {
        console.log('HotelList/watch')
        router.push({ path: '/no-hotels-available' })
        return
      } else if (
        airline.value?.params.default_sort_order &&
        airline.value?.params.default_sort_order == SORT_HOTEL_ESTIMATE_COST
      ) {
        _.each(dataListHotel.value, function (hotel) {
          processParamTaxi({}, hotel).then((payload) => {
            hotel.taxi_cost =
              payload.assignTaxiParams && payload.assignTaxiParams.total_price ? payload.assignTaxiParams.total_price : 0
            if (hotel.distance_in_km > 0) {
              hotel.total_estimate_cost = parseFloat((hotel.taxi_cost + hotel.total).toFixed(2))
            } else {
              hotel.total_estimate_cost = parseFloat(hotel.total.toFixed(2))
            }
          })
        })
      }
    })

    // created
    if (!_.isEmpty(paxsGroup.value) && !_.isEmpty(airline.value) && !_.isEmpty(pax.value)) {
      initData()
    }

    return {
      loading,
      spinnerActive,
      hasCancelHotelVoucher,
      dataListHotel,
      handleBookHotel,
      availableChangeRoomAllocation,
      handleChangeRoomAllocation
    }
  }
})
